// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__MCXb1 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Proxima Nova Lt";
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.49px;
}
.header_header__MCXb1 .header_linkLogo__KO71R {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000000;
}
.header_header__MCXb1 .header_linkLogo__KO71R .header_logoName__3Pc-h {
  margin-left: 16px;
}
.header_header__MCXb1 .header_nav__EQvVR {
  width: 424px;
  display: flex;
  justify-content: space-between;
}
.header_header__MCXb1 .header_link__6b4HU {
  color: #000000;
  text-decoration: none;
}
.header_header__MCXb1 .header_login__Gpo37 {
  font-family: "Proxima Nova Lt";
  color: #006FED;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.49px;
  width: max-content;
  height: max-content;
}

@media (max-width: 768px) {
  .header_header__MCXb1 .header_nav__EQvVR {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/layout/header/header.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,8BAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,oBAAA;AACJ;AAEI;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,cAAA;AAAR;AAEQ;EACI,iBAAA;AAAZ;AAII;EACI,YAAA;EACA,aAAA;EACA,8BAAA;AAFR;AAKI;EACI,cAAA;EACA,qBAAA;AAHR;AAMI;EACI,8BAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,oBAAA;EACA,kBAAA;EACA,mBAAA;AAJR;;AAQA;EAEQ;IACI,aAAA;EANV;AACF","sourcesContent":[".header {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-family: 'Proxima Nova Lt';\n    color: #000000;\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 19.49px;\n\n    \n    .linkLogo {\n        display: flex;\n        align-items: center;\n        text-decoration: none;\n        color: #000000;\n\n        .logoName {\n            margin-left: 16px;\n        }\n    }\n\n    .nav {\n        width: 424px;\n        display: flex;\n        justify-content: space-between;\n    }\n\n    .link {\n        color: #000000;\n        text-decoration: none;\n    }\n\n    .login {\n        font-family: 'Proxima Nova Lt';\n        color: #006FED;\n        font-weight: 700;\n        font-size: 16px;\n        line-height: 19.49px;\n        width: max-content;\n        height: max-content;\n    }\n}\n\n@media (max-width: 768px) {\n    .header { \n        .nav {\n            display: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__MCXb1`,
	"linkLogo": `header_linkLogo__KO71R`,
	"logoName": `header_logoName__3Pc-h`,
	"nav": `header_nav__EQvVR`,
	"link": `header_link__6b4HU`,
	"login": `header_login__Gpo37`
};
export default ___CSS_LOADER_EXPORT___;
