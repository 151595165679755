// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formAutocomplete_wrapPopup__agjBW {
  background-color: #FFFFFF;
}
.formAutocomplete_wrapPopup__agjBW .formAutocomplete_disabled__54cR9 {
  cursor: default;
  color: rgba(0, 0, 0, 0.47);
}
.formAutocomplete_wrapPopup__agjBW .formAutocomplete_unDisabled__Btwgk {
  cursor: pointer;
  color: #000000;
}
.formAutocomplete_wrapPopup__agjBW .formAutocomplete_showSearch__vOVCv {
  height: 23px;
  font-size: 19px;
  font-weight: 400;
  line-height: 19px;
  text-transform: none;
}
.formAutocomplete_wrapPopup__agjBW .formAutocomplete_hideSearch__YQgMP {
  width: 24px;
  height: 24px;
}
.formAutocomplete_wrapPopup__agjBW .formAutocomplete_showSearch__vOVCv:hover {
  background-color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/formAutocomplete/formAutocomplete.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;AACI;EACI,eAAA;EACA,0BAAA;AACR;AACI;EACI,eAAA;EACA,cAAA;AACR;AAEI;EACI,YAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;AAAR;AAGI;EACI,WAAA;EACA,YAAA;AADR;AAGI;EACI,yBAAA;AADR","sourcesContent":[".wrapPopup {\n    background-color: #FFFFFF;\n\n    .disabled {\n        cursor: default;\n        color: rgba(0, 0, 0, 0.47);\n    }\n    .unDisabled {\n        cursor: pointer;\n        color: #000000;\n    }\n\n    .showSearch {\n        height: 23px;\n        font-size: 19px;\n        font-weight: 400;\n        line-height: 19px;\n        text-transform: none;\n    }\n\n    .hideSearch {\n        width: 24px;\n        height: 24px;\n    }\n    .showSearch:hover {\n        background-color: inherit;\n    }\n\n    // .popup {\n    //     position: absolute;\n    //     top: 0;\n    //     left: 0;\n    //     width: 444px;\n    //     height: 160px;\n    //     background-color: #FFFFFF;\n    //     padding: 24px;\n    //     display: flex;\n    //     z-index: 400;\n    // }\n\n    \n    // .currency {\n    //     width: 100%;\n    //     display: flex;\n    //     align-items: center;\n    //     justify-content: space-between;\n    //     font-size: 19.01px;\n    //     font-weight: 700;\n    //     line-height: 23.15px;\n    //     color: #000000;\n    //     padding: 0 24px;\n    //     background-color: red;\n    // }\n}\n\n\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapPopup": `formAutocomplete_wrapPopup__agjBW`,
	"disabled": `formAutocomplete_disabled__54cR9`,
	"unDisabled": `formAutocomplete_unDisabled__Btwgk`,
	"showSearch": `formAutocomplete_showSearch__vOVCv`,
	"hideSearch": `formAutocomplete_hideSearch__YQgMP`
};
export default ___CSS_LOADER_EXPORT___;
