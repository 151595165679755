import { useCallback } from 'react';
import {
  FormControl,
  Select as SelectMui,
  MenuItem,
} from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import type { SelectProps } from './selectComponent.interface';
import styles from './selectComponent.module.scss';
import clsx from 'clsx';

export const SelectComponent = (props: SelectProps) => {
  const {
    labelId = 'select-label',
    onChange,
    label,
    value,
    menuItems,
    styled,
    variant,
    className,
    ...rest
  } = props;

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      if (onChange) {
        onChange(event.target.value as string);
      }
    },
    [onChange],
  );

  return (
    <FormControl>
      <SelectMui
        ref={null}
        value={value}
        className={clsx(styles.selectMui, className) }
        labelId={labelId}
        label={label}
        variant={variant}
        {...rest}
        onChange={handleChange}
        sx={{color: '#C2C2C2', boxShadow: 'none',  }}
      >
        {menuItems.map((el, index) => {
          const { value, label } = el;

          return (
            <MenuItem
              className={styles.MenuItems}
              key={`${value} - ${index}`}
              value={value}
            >
              {label}
            </MenuItem>
          );
        })}
      </SelectMui>
    </FormControl>
  );
};