// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_layout__-gEDN {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-areas: "head" "main" "footer";
  grid-template-columns: 12fr;
  grid-template-rows: 1fr 9fr 2fr;
  overflow-y: scroll;
}

.layout_head__lKro3 {
  grid-area: head;
  min-width: var(--main-size-lg);
  min-height: 158px;
  justify-self: center;
  padding: 0 42px;
}

.layout_main__HAbme {
  grid-area: main;
  width: 100%;
  max-width: var(--main-size-lg);
  justify-self: center;
}

.layout_footer__l6lS6 {
  grid-area: footer;
  background-color: rgba(248, 248, 248, 0.9294117647);
  width: 100%;
}

@media (max-width: 1024px) {
  .layout_head__lKro3 {
    min-width: var(--main-size-md);
  }
  .layout_main__HAbme {
    justify-self: auto;
  }
}
@media (max-width: 768px) {
  .layout_head__lKro3 {
    min-width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/layout/layout.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,aAAA;EACA,2CACA;EAGA,2BAAA;EACA,+BAAA;EAEA,kBAAA;AAHJ;;AAMA;EACI,eAAA;EACA,8BAAA;EACA,iBAAA;EACA,oBAAA;EACA,eAAA;AAHJ;;AAMA;EACI,eAAA;EACA,WAAA;EACA,8BAAA;EACA,oBAAA;AAHJ;;AAOA;EACI,iBAAA;EACA,mDAAA;EACA,WAAA;AAJJ;;AAUA;EAEI;IACI,8BAAA;EARN;EAWE;IACI,kBAAA;EATN;AACF;AAaA;EAEI;IACI,eAAA;EAZN;AACF","sourcesContent":[".layout {\n    width: 100vw;\n    height: 100vh;\n    display: grid;\n    grid-template-areas:\n    \"head\"\n    \"main\"\n    \"footer\";\n    grid-template-columns: 12fr;\n    grid-template-rows: 1fr 9fr 2fr;\n    // overflow-x: hidden;\n    overflow-y: scroll;\n}\n\n.head {\n    grid-area: head;\n    min-width: var(--main-size-lg);\n    min-height: 158px;\n    justify-self: center;\n    padding: 0 42px;\n}\n\n.main {\n    grid-area: main;\n    width: 100%;\n    max-width: var(--main-size-lg);\n    justify-self: center;\n\n}\n\n.footer {\n    grid-area: footer;\n    background-color: #F8F8F8ED;\n    width: 100%;\n}\n\n\n\n\n@media (max-width: 1024px) {\n\n    .head {\n        min-width: var(--main-size-md);\n    }\n    \n    .main {\n        justify-self: auto;\n    }\n\n}\n\n@media (max-width: 768px) {\n\n    .head {\n        min-width: 100%;\n    }\n    \n    \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `layout_layout__-gEDN`,
	"head": `layout_head__lKro3`,
	"main": `layout_main__HAbme`,
	"footer": `layout_footer__l6lS6`
};
export default ___CSS_LOADER_EXPORT___;
