import axios from "axios";
import { ConvertRequest, CreateTransferMoney, GetListCountry } from "./api.interface";

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
    withCredentials: true,
    timeout: 10 * 10000,
});

export interface ConvertCurrency {
    access_key: string;
    from: string;
    to: string;
    amount: string;
}

export const baseApi = {
    async convertCurrency(props:any) {
        // console.log('process.env', process.env.REACT_APP_ACCESS_KEY)
        const params = {
            ...props,
        } 
        try {
            return (await ( axios.get('http://api.exconvert.com/convert', {params}))).data
            
        } catch (error) {
            return error;
        }
    // .then(response => {
    //   const websiteContent = response.data;
    //   console.log(websiteContent);
    // }).catch(error => {
    //   console.log(error);
    // }),
    },
    async currenciesLimit () {
        try {
           return ((await instance.get(`/currencies/list-with-limits`)).data)
        } catch (error:any) {
            if(error?.response?.data?.message) {
                console.error('error',error?.response?.data?.message);
            }
            console.error('error', error)
        }
    },
    async countryCity () {
        try {
           return ((await instance.get(`/cities/countries-and-cities`)).data)
        } catch (error:any) {
            if(error?.response?.data?.message) {
                console.error('error',error?.response?.data?.message);
            }
            console.error('error', error)
        }
    },
    async telegramCount (props:number) {
        try {
           return ((await instance.get(`/requests/count?telegramId=${props}`)).data)
        } catch (error:any) {
            if(error?.response?.data?.message) {
                console.error('error',error?.response?.data?.message);
            }
            console.error('error', error)
        }
    },
    async telegramList (props:number) {
        try {
           return ((await instance.get(`/requests/list?telegramId=${props}`)).data)
        } catch (error:any) {
            if(error?.response?.data?.message) {
                
                console.error('error',error?.response?.data?.message);
            }
            console.error('error', error)
        }
    },
    async convertCurrencyAndValidate (value: ConvertRequest) {
        try {
           return ((await instance.post(`/currencies/validate-limits-and-convert`, value)).data)
        } catch (error:any) {
            if(error?.response?.data?.message) {
                return error?.response?.data
            }
            console.error('error', error)
            return error
        }
    },
    async getCurrencyList (value: GetListCountry) {
        try {
           return ((await instance.get(`/currencies/list-with-labels?country=${value.country}&city=${value.city}`)).data)
        } catch (error:any) {
            if(error?.response?.data?.message) {
                return error?.response?.data
            }
            console.error('error', error)
            return error
        }
    },
    async postCreateTransfer (value: CreateTransferMoney) {
        const config = {
            headers: { 'token': process.env.REACT_APP_ACCESS_KEY }
        }
        try {
           return ((await instance.post(`/requests/create`, value, config)).data)
        } catch (error:any) {
            if(error?.response?.data?.message) {
                console.log('(error', error?.response?.data?.message)
                return error?.response?.data
            }
            console.error('error', error)
            return error
        }
    },
}