import { createTheme } from "@mui/material";

export const theme = createTheme({
    typography: {
      fontFamily: 'Proxima Nova Rg',
    },
    palette: {
        primary: {
            main: '#006FED',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#E9E9E9',
            contrastText: '#A1A1A1'
        },
        error: {
          main: '#ED0000',
          contrastText: '#FFFFFF'
        },
        success: {
          main: '#006FED',
          contrastText: '#FFFFFF'
        }
    },
    components: {
      MuiTypography: {
        styleOverrides: {
            root:{
              cursor: 'default',
              textTransform: 'none'
            },
            h1: {
                fontSize: 36,
                fontWeight: 700,
                lineHeight: '44px',
                color: '#000000'
            },
            body1: {
                fontSize: 28,
                fontWeight: 700,
                lineHeight: '34px',
                color: '#000000'
            },
            body2: {
                fontSize: 16,
                fontWeight: 700,
                lineHeight: '20px',
                color: '#000000'
            },
            subtitle1: {
                fontWeight: 700,
                fontSize: 18,
                lineHeight: '19.8px',
                color: '#000000'
            },
            subtitle2: {
                fontWeight: 400,
                fontSize: 15,
                lineHeight: '16.5px',
                color: '#000000'
            },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            fontSize: '15px',
            fontWeight: 700,
            lineHeight: '18.27px',
            color: '#000000',
            borderRadius: '10px',

            '&.MuiToggleButton-root.Mui-selected': {
              backgroundColor: '#006FED',
              color: '#FFFFFF'
            }
          }
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            height: '32px'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            fontFamily: 'Proxima Nova Rg',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '21.9px',
            textTransform: 'uppercase',
            width: '100%',
            height: '67px',
            '&.MuiButton-containedPrimary:hover': {
                backgroundColor: '#074d9d',
                color: '#FFFFFF',
                boxShadow: 'none'
              },
            '&.MuiButton-containedSecondary:hover': {
                backgroundColor: '#d3d3d3',
                color: '#FFFFFF',
                 boxShadow: 'none'
            },
          },
        }
      },
      MuiSelect: {
        styleOverrides: {
           
            root: {
                color: '#C2C2C2',
                backgroundColor: '#F5F5F5',
                '&.Mui-focused': {
                    color: '#FFFFFF',
                    backgroundColor: '#006FED',
                }
            },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
            root: {
                fontFamily: 'Proxima Nova Rg',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19.49px',
                color: '#C2C2C2',
                padding: '6px 6px 6px 18px'
            }
        }
      },
      MuiList: {
        styleOverrides: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            height: '63px'
          },
          clearIndicator: {
            color: "#006FED"
          },
            listbox: {
              padding: 0,
              fontFamily: 'Proxima Nova Rg',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '19.49px',
              textTransform: 'none',
               "& .MuiAutocomplete-option": {
                  color: '#000000',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: '0',
                  paddingRight: '24px',
                  paddingLeft: '24px',
                  fontSize: '19.01px',
                  fontWeight: 400,
                  lineHeight: '23.15px'
              }
            },
            input: {
                height: '16px',
                fontSize: '19.01px',
                fontWeight: 400,
                lineHeight: '23.15px'
            }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            ":before": {
              backgroundColor:'#006FED',
            }
          },
          tooltip: {
            backgroundColor: '#006FED',
            color: '#FFFFFF'
          }
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '& input[type=number]': {
              'MozAppearance': 'textfield'
          },
          '& input[type=number]::-webkit-outer-spin-button': {
              'WebkitAppearance': 'none',
              margin: 0
          },
          '& input[type=number]::-webkit-inner-spin-button': {
              'WebkitAppearance': 'none',
              margin: 0
          }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `0px`,
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                border: `0px`,
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '19.49px',
            color: '#C2C2C2',
            "&.Mui-focused": {
              color: '#C2C2C2',
            }
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer'
          }
        }
      }
    },
  })