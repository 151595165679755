import { ReactComponent as Logo} from 'assets/icon/logo.svg';
import styles from './header.module.scss'
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

export const Header = () => {
  return (
    <Box className={styles.header}>
        <Link className={styles.linkLogo} to={'/'}>
            <Logo />
            <span className={styles.logoName}>EMPO</span>
        </Link>
        <nav>
            <Link className={styles.link} to={'/'}>
                ГЛАВНАЯ
            </Link>
            {/* <Link className={styles.link} to={'/FAQ'}>
                FAQ
            </Link> */}
            {/* <Link className={styles.link} to={'/support'}>
                О СЕРВИСЕ
            </Link> */}
            <Link className={styles.link} to={'/about'}>
                О СЕРВИСЕ
            </Link>
        </nav>

        {/* <Button className={styles.login}>
            Войти
        </Button> */}
    </Box>
  )
}
