import Joi from "joi";

export const schemaValidationMain = Joi.object({
    variable: Joi.string().required(),
    rules: Joi.boolean().invalid(false),
    from: {
      city: Joi.string().required(),
      option: Joi.string().required(),
      currency: Joi.string().required(),
      sum: Joi.number().invalid(null, 0).required(),
    // .custom((value, helpers) => {
    //   const { currency } = helpers.state.ancestors[0];
    //   // Устанавливаем минимальное значение в зависимости от валюты
    //   let minAmount
    //   switch (currency) {
    //     case 'USD':
    //       minAmount = 5000;
    //       break;
    //     case 'EUR':
    //       minAmount = 5000;
    //       break;
    //     case 'RUB':
    //       minAmount = 50000;
    //       break;
    //     case 'CNY':
    //       minAmount = 35000;
    //       break;
    //     case 'USDT':
    //       minAmount = 1800;
    //       break;
    //     default:
    //       minAmount = 0;
    //   }
    //   if (value < minAmount) {
    //     return helpers.error(
    //       'any.invalid', {message: `Сумма ====== должна быть не меньше ${minAmount} ${currency}` }
    //     );
    //   }
    //   return value; // Возвращаем валидное значение
    // }),
  },
  to: {
    city: Joi.string().required(),
    option: Joi.string().required(),
    currency: Joi.string().required(),
    sum: Joi.number().required().invalid(null, 0),/* .invalid(null).custom((value, helpers) => {
            const { city } = helpers.state.ancestors[0];
            // Устанавливаем минимальное значение в зависимости от валюты
            
            // let minTo = 0;

            const foo = (test: string) => {
              if(test.includes('Германия')) {
                console.log('testTRUE')
                return 10000;
              }
              
              if(test.includes('Россия')) {
                return 5000;
              }

              // } else {
              //   console.log('testFalse')

              //   return 0
              // }
                return 0
            }

            // switch (city) {
            //   case 'Египет':
            //     minTo = 10000;
            //     break;
            //   case 'Россия':
            //     minTo = 1000;
            //     break;
            // }
              console.log('minTo', value, foo(city), city)
            if (value < foo(city)) {
              return helpers.error(
                'any.invalid', {message: `Сумма должна получения быть не меньше ` }
              );
            }

            return value; // Возвращаем валидное значение
          }) */
  },
});

  