// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.notFound_wrap__rpi9i {
  width: 1024px;
  display: flex;
  justify-content: center;
}
.notFound_wrap__rpi9i .notFound_container__SzXIU {
  max-width: 308px;
  margin-top: 64px;
}
.notFound_wrap__rpi9i .notFound_container__SzXIU .notFound_notFoundNumber__zqKBk {
  font-size: 170px;
  font-weight: 700;
  color: #F0F0F0;
}
.notFound_wrap__rpi9i .notFound_container__SzXIU .notFound_notFoundNumber__zqKBk::after {
  content: "🥺";
  display: flex;
  align-items: center;
  font-size: 130px;
  position: relative;
  left: 62px;
  width: 130px;
  height: 130px;
}
.notFound_wrap__rpi9i .notFound_container__SzXIU .notFound_textContent__xDFi9 {
  margin-top: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.notFound_wrap__rpi9i .notFound_container__SzXIU .notFound_textContent__xDFi9 .notFound_subtitle__PcYqq {
  margin-top: 18px;
  margin-bottom: 60px;
}`, "",{"version":3,"sources":["webpack://./src/pages/notFound/notFound.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,aAAA;EACA,aAAA;EACA,uBAAA;AAEJ;AAAI;EACI,gBAAA;EACA,gBAAA;AAER;AAAQ;EACI,gBAAA;EACA,gBAAA;EACA,cAAA;AAEZ;AAAQ;EACQ,aAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;AAEhB;AACQ;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;AACZ;AACY;EACI,gBAAA;EACA,mBAAA;AAChB","sourcesContent":[".wrap {\n    width: 1024px;\n    display: flex;\n    justify-content: center;\n    \n    .container {\n        max-width: 308px;\n        margin-top: 64px;\n\n        .notFoundNumber {\n            font-size: 170px;\n            font-weight: 700;\n            color: #F0F0F0;\n        }\n        .notFoundNumber::after {\n                content: '🥺';\n                display: flex;\n                align-items: center;\n                font-size: 130px;\n                position: relative;\n                left: 62px;\n                width: 130px;\n                height: 130px;\n        }\n\n        .textContent {\n            margin-top: 36px;\n            display: flex;\n            align-items: center;\n            text-align: center;\n            flex-direction: column;\n\n            .subtitle {\n                margin-top: 18px;\n                margin-bottom: 60px;\n            }\n        }\n\n    \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `notFound_wrap__rpi9i`,
	"container": `notFound_container__SzXIU`,
	"notFoundNumber": `notFound_notFoundNumber__zqKBk`,
	"textContent": `notFound_textContent__xDFi9`,
	"subtitle": `notFound_subtitle__PcYqq`
};
export default ___CSS_LOADER_EXPORT___;
