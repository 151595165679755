export const defaultCurrencyFrom = [
    {
        currency: "RUB",
        min: 150000
    },
    {
        currency: "CNY",
        min: 12500
    },
    {
        currency: "USD",
        min: 1800
    },
    {
        currency: "EUR",
        min: 1500
    },
    {
        currency: "THB",
        min: 60000
    },
    {
        currency: "IDR",
        min: 30000000
    },
    {
        currency: "KZT",
        min: 850000
    },
    {
        currency: "TRY",
        min: 60000
    },
    {
        currency: "KRW",
        min: 2500000
    },
    {
        currency: "GEL",
        min: 4500
    },
    {
        currency: "AMD",
        min: 650000
    },
    {
        currency: "KGS",
        min: 150000
    },
    {
        currency: "AZN",
        min: 3000
    },
    {
        currency: "INR",
        min: 150000
    },
    {
        currency: "USDT",
        min: 1800
    }
]
export const defaultCurrencyTo = [
    {
        currency: "USD",
        min: 10000
    },
    {
        currency: "USDT",
        min: 1800
    }
]


const findFrom = (el: any) => {
    const element = defaultCurrencyFrom.find((item) => item.currency === el);
    return `${element?.min} ${element?.currency}`;
}


const findTo = (el: any) => {
    const element = defaultCurrencyTo.find((item) => item.currency === el);
    return `${element?.min} ${element?.currency}`;
}

export const handleError = (error: any, currency?: any, isFrom?: boolean) => {

    console.log('error', error?.from?.sum?.type === 'any.invalid')

    if(error?.from?.sum?.type === 'any.invalid')  return `Ошибка! Минимальная сумма от ${findFrom(currency)}`
    if(error?.from?.sum?.type === 'number.base')  return`Поле не может быть пустым!`
    if(error?.to?.sum?.type === 'any.invalid') return `Сумма получения должна быть не меньше ${findTo(currency)}`
    if(error?.to?.sum?.type === 'any.custom') return 'Для начала выберите город!'
    if(error?.to?.city?.type === 'any.only') return 'Выберете другой город!'
    if(typeof error === 'string') return error;
    return 'ОШИБКА!'
    // switch (error) {
    //     case error?.from?.sum?.type === 'any.invalid':
    //         return  `Сумма получения должна быть не меньше ${findTo(currency)}`
    //     case error?.from?.sum?.type === 'number.base':
    //         return `Поле не может быть пустым!`
    //     case error?.to?.sum?.type === 'any.invalid':
    //         return `Ошибка! Минимальная сумма от ${findFrom(currency)}`
    //     default:
    //        return `${ String(error?.from?.sum.type)}`
    // }
}