import { Box, Typography } from '@mui/material';
import styles from './welcomeCards.module.scss';
import { ReactComponent as LogoCard } from 'assets/icon/logoCard.svg';
import { ReactComponent as BlackStar } from 'assets/icon/blackStar.svg';
import clsx from 'clsx';

export const WelcomeCards = () => {
  return (
    <Box className={styles.wrapCards}>
      <Box className={clsx(styles.baseCard, styles.blueCard)}>
        <Box >
          <Typography className={clsx(styles.title, styles.textWhite)}>Самые выгодные<br/> условия от 0,2%</Typography>
          <Typography className={clsx(styles.subTitle, styles.textWhite, styles.margins)}>Безопасно. Быстро.<br/> По всему миру.</Typography>
          <Typography className={styles.description}>RUB - CRYPTO - USD</Typography>
        </Box>
        <LogoCard />
      </Box>

      <Box className={clsx(styles.baseCard, styles.grayCard)}>
        <Box>
          <Typography className={clsx(styles.title, styles.textBlack)}>Оцените на</Typography>
          <Box className={styles.cardContainer}>
            <BlackStar/>
            <Typography className={clsx(styles.title, styles.textBlack)}>TRUSTPILOT</Typography>
          </Box>
        </Box>
        <Typography className={clsx(styles.subTitle, styles.grayText)}>Выбор пользователей.</Typography>
      </Box>
    </Box>
  )
}
