// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exchangeRules_container__tlUZO .exchangeRules_weight__7Yn2F {
  font-weight: 700;
}
.exchangeRules_container__tlUZO .exchangeRules_subtitle__OzEjV {
  margin-top: 24px;
}
.exchangeRules_container__tlUZO .exchangeRules_description__HznXx {
  margin-top: 14px;
}
.exchangeRules_container__tlUZO .exchangeRules_liContent__CSCHX {
  margin: 14px 0;
}
.exchangeRules_container__tlUZO .exchangeRules_title__E8IQj {
  font-size: 18px;
  font-weight: 700;
  line-height: 19.8px;
}
.exchangeRules_container__tlUZO ol {
  counter-reset: item;
}
.exchangeRules_container__tlUZO li {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 17.6px;
  margin: 14px 0;
}
.exchangeRules_container__tlUZO li::before {
  font-weight: 600;
}
.exchangeRules_container__tlUZO li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

@media (max-width: 1024px) {
  .exchangeRules_container__tlUZO .exchangeRules_title__E8IQj {
    font-size: 16px;
    line-height: 17.6px;
  }
  .exchangeRules_container__tlUZO ol {
    padding-left: 24px;
  }
}
@media (max-width: 768px) {
  .exchangeRules_container__tlUZO ol {
    padding-left: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/exchangeRules/exchangeRules.module.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;AADR;AAII;EACI,gBAAA;AAFR;AAKI;EACI,gBAAA;AAHR;AAMI;EACI,cAAA;AAJR;AAOI;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;AALR;AAQI;EACI,mBAAA;AANR;AASI;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AAPR;AAUI;EACI,gBAAA;AARR;AAWI;EAAY,iCAAA;EAAmC,uBAAA;AAPnD;;AAUA;EAGQ;IACI,eAAA;IACA,mBAAA;EATV;EAYM;IACI,kBAAA;EAVV;AACF;AAeA;EAEQ;IACI,kBAAA;EAdV;AACF","sourcesContent":[".container {\n\n    .weight {\n        font-weight: 700;\n    }\n\n    .subtitle {\n        margin-top: 24px;\n    }\n\n    .description {\n        margin-top: 14px;\n    }\n\n    .liContent {\n        margin: 14px 0;\n    }\n\n    .title {\n        font-size: 18px;\n        font-weight: 700;\n        line-height: 19.8px;\n    }\n\n    ol { \n        counter-reset: item;\n    }\n\n    li { \n        display: block; \n        font-size: 16px;\n        font-weight: 400;\n        line-height: 17.6px;\n        margin: 14px 0;\n    }\n\n    li::before {\n        font-weight: 600;\n    }\n\n    li:before { content: counters(item, \".\") \". \"; counter-increment: item }\n}\n\n@media (max-width: 1024px) {\n    .container {\n\n        .title {\n            font-size: 16px;\n            line-height: 17.6px;\n        }\n\n        ol {\n            padding-left: 24px;\n        }\n    }\n}\n\n\n@media (max-width: 768px) {\n    .container {\n        ol {\n            padding-left: 12px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `exchangeRules_container__tlUZO`,
	"weight": `exchangeRules_weight__7Yn2F`,
	"subtitle": `exchangeRules_subtitle__OzEjV`,
	"description": `exchangeRules_description__HznXx`,
	"liContent": `exchangeRules_liContent__CSCHX`,
	"title": `exchangeRules_title__E8IQj`
};
export default ___CSS_LOADER_EXPORT___;
