import { Box, Button, Typography } from "@mui/material";
import styles from './notFound.module.scss';

export const NotFound = () => {
  return (
    <Box className={styles.wrap}>
        <Box className={styles.container}>
        <Typography className={styles.notFoundNumber}>
            404
        </Typography>

        <Box className={styles.textContent}>
            <Typography variant="body1">
                Странно, страница <br/>
                не найдена
            </Typography>

            <Typography className={styles.subtitle} variant="subtitle2">
                Мы уже вкурсе данной <br/> проблемы, решаем в процессе.
            </Typography>

            <Button variant="contained" color="primary">
                Вернуться назад
            </Button>
        </Box>
        </Box>
    </Box>
  )
}
