import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import  {  SyntheticEvent, useEffect, useState } from 'react';
import styles from './customCurrencyAutocomplete.module.scss';
import { Controller } from 'react-hook-form';

export const CustomCurrencyAutocomplete = (props: any) => {
    const { 
        init, 
        array, 
        zIndex, 
        renderOption,
        isOptionEqualToValue,
        getOptionDisabled,
        control, 
        name, 
        disabled, 
        handleBlur, 
        customOnChange,
        customSupportOnChange,
        customSetName,
        placeholder, 
        isOpenCurrency,
        handleOpenCurrency,
        handleCloseCurrency
      } = props;
    
      const [valueInit, setValueInit] = useState(init);
    
      useEffect(()=> {
        setValueInit(init)
      },[init])
    
    
      const handleOpen = () => {
          if(!disabled) {
            handleOpenCurrency()
          }
      }
    
        const onMouseLeave = () => {
            console.log('leeeave')
          handleCloseCurrency()
        }

        const handleSearch = (_: SyntheticEvent<Element, Event>, newValue: string | any, onChange:any
        ) => {
            onChange(newValue.value)
            setValueInit(newValue.value)
            handleCloseCurrency();
        }           

    return (
            <Controller
                control={control}
                name={name}
                render={({ field: { value, onChange, onBlur, ...fieldProps } }) => (
                <Box style={{zIndex: zIndex}} className={styles.popup} onMouseLeave={onMouseLeave} >
                    <Autocomplete
                     
                        {...fieldProps}
                        ListboxProps={{
                          className: 'scrollbarCustom'
                        }}
                        open={isOpenCurrency}
                        disableClearable
                        forcePopupIcon={false}
                        onOpen={handleOpen}
                        getOptionDisabled={getOptionDisabled}
                        fullWidth
                        value={value}
                        onChange={(e ,value) => handleSearch(e, value, onChange)}
                        size='small'
                        // disablePortal
                        options={array}
                        renderOption={renderOption}
                        isOptionEqualToValue={isOptionEqualToValue}
                        renderInput={(params) => (
                            <TextField   
                                sx={{
                                    '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                                    padding: '0',
                                    pt:'2px',      
                                }}} 
                                {...params}  
                                focused  
                                placeholder={placeholder}  
                            />)}
                        />
                </Box>
            )}/>
    )
}
