// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about_container__SXb4g .about_subtitle__naiF3 {
  margin-top: 24px;
}
.about_container__SXb4g .about_nameService__n7Avb {
  font-weight: 800;
}
.about_container__SXb4g .about_description__qFQBk {
  margin-top: 14px;
}`, "",{"version":3,"sources":["webpack://./src/pages/about/about.module.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;AADR;AAII;EACI,gBAAA;AAFR;AAKI;EACI,gBAAA;AAHR","sourcesContent":[".container {\n\n    .subtitle {\n        margin-top: 24px;\n    }\n\n    .nameService {\n        font-weight: 800;\n    }\n\n    .description {\n        margin-top: 14px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `about_container__SXb4g`,
	"subtitle": `about_subtitle__naiF3`,
	"nameService": `about_nameService__n7Avb`,
	"description": `about_description__qFQBk`
};
export default ___CSS_LOADER_EXPORT___;
