// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_layout__-gEDN {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-areas: "head" "main" "footer";
  grid-template-columns: 12fr;
  grid-template-rows: 1fr 9fr 2fr;
}

.layout_head__lKro3 {
  grid-area: head;
  min-width: 1024px;
  min-height: 158px;
  justify-self: center;
  padding: 0 42px;
}

.layout_main__HAbme {
  grid-area: main;
  max-width: 1024px;
  min-width: 1024px;
  justify-self: center;
}

.layout_footer__l6lS6 {
  grid-area: footer;
  background-color: rgba(248, 248, 248, 0.9294117647);
}`, "",{"version":3,"sources":["webpack://./src/layout/layout.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,2CACA;EAGA,2BAAA;EACA,+BAAA;AAFJ;;AAMA;EACI,eAAA;EACA,iBAAA;EACA,iBAAA;EAEA,oBAAA;EACA,eAAA;AAJJ;;AAOA;EACI,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,oBAAA;AAJJ;;AAOA;EACI,iBAAA;EACA,mDAAA;AAJJ","sourcesContent":[".layout {\n    width: 100%;\n    height: 100vh;\n    display: grid;\n    grid-template-areas:\n    \"head\"\n    \"main\"\n    \"footer\";\n    grid-template-columns: 12fr;\n    grid-template-rows: 1fr 9fr 2fr;\n    \n}\n\n.head {\n    grid-area: head;\n    min-width: 1024px;\n    min-height: 158px;\n    // align-self: center;\n    justify-self: center;\n    padding: 0 42px;\n}\n\n.main {\n    grid-area: main;\n    max-width: 1024px;\n    min-width: 1024px;\n    justify-self: center;\n}\n\n.footer {\n    grid-area: footer;\n    background-color: #F8F8F8ED;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `layout_layout__-gEDN`,
	"head": `layout_head__lKro3`,
	"main": `layout_main__HAbme`,
	"footer": `layout_footer__l6lS6`
};
export default ___CSS_LOADER_EXPORT___;
