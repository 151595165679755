import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format';



export const FormInputMoney = (props: any) => {
    const { 
        control, 
        name, 
        className, 
        variant, 
        color, 
        placeholder, 
        fullWidth, 
        InputProps, 
        inputProps, 
        handleBlur,
        disabled,
        prefix,
        customOnChange
    } = props;

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: {value, ...restField} }) => (
                <NumericFormat
                    value={value}
                    customInput={TextField}
                    onBlur={() => !!handleBlur ?  handleBlur() : restField.onBlur}
                    inputProps={inputProps}
                    InputProps={InputProps}
                    fullWidth={fullWidth} 
                    className={className} 
                    placeholder={placeholder} 
                    variant={variant} 
                    color={color} 
                    disabled={disabled}
                    prefix={prefix && prefix}
                    thousandSeparator=" "
                    decimalSeparator=","
                    onValueChange={(values) => {
                        !!customOnChange && customOnChange(values.floatValue);
                        restField.onChange(values.floatValue);
                    }}
                />
            )}
        />
    )
}

 // <TextField
                    // value={value}
                    // onChange={onChange}
                    // onBlur={() => !!handleBlur ?  handleBlur(onBlur) : onBlur}
                    // type='number'
                    // inputProps={inputProps}
                    // InputProps={InputProps}
                    // fullWidth={fullWidth} 
                    // className={className} 
                    // placeholder={placeholder} 
                    // variant={variant} 
                    // color={color} 
                // />