import { Box, Button, Typography } from '@mui/material';
import { AccordionComponent } from 'components';
import { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import { arrCrypto, arrInternational } from 'constants/FAQ';
import { DescriptionWrapper } from 'components/descriptionWrapper/DescriptionWrapper';
import styles from './faq.module.scss';


export const Faq = () => {

    const [open, setOpen] = useState(0);

    const openFaq = (e:ChangeEvent<HTMLInputElement>) => {
        setOpen(+e.target.id)
    }

    const selectFaq = (selected:number) => {
        switch (selected) {
            case 0:
                return arrInternational
            case 1:
                return arrCrypto
        
            default:
                return null
        }
    }
    return (
        <Box>
            <DescriptionWrapper>
                <Typography className={styles.title}>
                        FAQ
                </Typography>
                <Box className={styles.container}>
                    <Box className={styles.categories}>
                        <Button className={clsx(styles.category, open === 0 && styles.active)} onClick={(e: any)=> openFaq(e)} id='0'>
                            Переводы
                        </Button>
                        <Button className={clsx(styles.category,  open === 1 && styles.active)} onClick={(e: any)=> openFaq(e)} id='1'>
                            Покупка/продажа USDT
                        </Button>
                    </Box>
                    <Box className={styles.contentFaq}>
                        <AccordionComponent styled={styles.accordionStyle} arrAccordion={selectFaq(open)}/>
                    </Box>
                </Box>
            </DescriptionWrapper>
        </Box>
    )
}
