import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import clsx from 'clsx';
import { MOCK_VARIABLE } from 'constants/MOCK_CONST';
import { FormCustomAutocomplete, FormInputMoney, FormSelect } from 'components';
import { handleError } from 'shared';
import styles from './panelTransferMoney.module.scss'
import { baseApi } from 'api';
import { checkSubmitConvert, renderOptionAutocomplete, renderOptionValueLabelAutocomplete } from 'shared';
import { SelectCurrency } from 'api/api.interface';
import { isOptionEqual, isOptionEqualValue } from 'shared/helpers/mainHelpers/mainHelpers';


export const PanelTransferMoney = (props: any) => {
    const { 
        control,
        getValues, 
        setValue, 
        errors,
        fromCity,
        toCity,
        setToCity,
        setFromCity,
        onChangeData,
        currencyFrom,
        currencyTo,
        setCurrencyFrom,
        setCurrencyTo,
        errorFrom,
        errorTo,
        handleConvertCurrency,
        clearResponseError,
    } = props;

    const [arrayCountryCity, setArrayCountryCity] = useState<any[]>([]);
    const [arrayFromCurrency, setArrayFromCurrency] = useState<any[]>([]);
    const [arrayToCurrency, setArrayToCurrency] = useState<any[]>([]);

    useEffect(()=> {
        setCurrencyFrom(getValues('from.currency'));
        setCurrencyTo(getValues('to.currency'));
    }, [errors, getValues('from.currency'), getValues('to.currency')]);

    
    const handleSwap = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const data = getValues();

        console.log('dataPrev', data)
        setFromCity(data.to.city);
        setToCity(data.from.city);
        setCurrencyFrom(data.to.currency);
        setCurrencyTo(data.from.currency);

        setValue('from', data.to);
        setValue('to', data.from);


        onChangeData();

        if(
            checkSubmitConvert(data)
        ) {
            handleConvertCurrency()
        }
    }

    useEffect(()=> {
        (async() => {
            const responseCountryCity = await baseApi.countryCity();
            
            setArrayCountryCity(responseCountryCity?.map((el:any) => `${el.country}, ${el.city}`))
        })()
    },[])

    const handleCurrency = useCallback( async(getName: string, setName: string) => {
        clearResponseError()
        const data = getValues(getName);
        const dataConvert = getValues();

        const response = await baseApi.getCurrencyList({
            country: data?.split(',')[0]?.trim(),
            city: data?.split(',')[1]?.trim() 
        })

        setName.includes('from') ? 
        setArrayFromCurrency(response?.map((el:SelectCurrency) => ({ 
            label: el.label, 
            value: el.value
        })))
        :
        setArrayToCurrency(response?.map((el:SelectCurrency) => ({ 
            label: el.label, 
            value: el.value
        })))

        setValue(setName, response.at(0)?.value);

        if(
            checkSubmitConvert(dataConvert)
        ) {
            console.log('ONE')
            handleConvertCurrency()
        }
        onChangeData()
       
    }, []);

    const handleSupportConvert = () => {
        const dataConvert = getValues();
        if(
            checkSubmitConvert(dataConvert)
        ) {
            
            handleConvertCurrency()
        }
    }

    return (
        <Box className={styles.panel} >
             
            <Box className={clsx(Boolean(errors?.from) === true || errorFrom.length > 1 ? styles.panelErrorsTop : styles.panelInputs  )}>
                <Box className={styles.selects}>
                    <Box className={styles.selectType}>
                        <Typography sx={{color: '#C2C2C2'}} variant='subtitle2'>Отдаю</Typography>
                        {
                            (fromCity !== 'Tether (USDT)') &&
                            <Box className={styles.wrapSelect}>
                                <FormSelect
                                    size="small"
                                    fullWidth={false}
                                    control={control}
                                    name={'from.option'}
                                    menuItems={MOCK_VARIABLE}
                                />
                            </Box>
                        }
                    </Box>
                    <FormCustomAutocomplete
                        customOnChange={handleCurrency}
                        customSetName='from.currency'
                        getOptionDisabled={(option: string) =>
                            option === getValues('to.city')
                        }
                        isShowTitle
                        placeholder={'Выберите город'}
                        renderOption={renderOptionAutocomplete}
                        isOptionEqualToValue={isOptionEqual}
                        control={control}
                        name={'from.city'}
                        disabled={getValues('from.city') === 'Tether (USDT)'}
                        zIndex='400' 
                        init={fromCity || getValues('from.city')} 
                        array={arrayCountryCity} 
                        label="Поиск офиса по городам" 
                    />
                </Box>
                <Box className={styles.inputSum}>
                    <FormInputMoney
                        handleBlur={handleConvertCurrency}
                        disabled={fromCity === null}
                        control={control} 
                        name={'from.sum'}
                        fullWidth 
                        placeholder={'50000'}
                        InputProps={{
                            inputProps: {
                                style: { textAlign: "right" },
                            }
                        }} 
                    />
                    <FormCustomAutocomplete
                        customSupportOnChange={handleSupportConvert}
                        disabled={getValues('from.currency') === 'USDT' || fromCity === null}
                        control={control}
                        name={'from.currency'}
                        renderOption={renderOptionValueLabelAutocomplete}
                        isOptionEqualToValue={isOptionEqualValue}
                        isChangeValue={true}
                        zIndex='400'
                        init={currencyFrom}
                        array={arrayFromCurrency}
                        label="Выбор валюты"
                    />
                </Box>
            </Box>
            {(Boolean(errors?.from) === true || errorFrom.length > 1 ) && <Box className={styles.errors} >{
            handleError(errorFrom.length > 1 
            ? errorFrom 
            :   errors, currencyFrom, true)}</Box>}
            <Box className={styles.divider}>
                <Divider sx={{width: '100%', height: '2px' }}/>
                <Button disabled={fromCity === null || toCity === null} onClick={handleSwap} className={styles.swapButton} endIcon={<SwapVertIcon/>}>CRPT</Button>
            </Box>
            <Box className={clsx((Boolean(errors.to) === true  || errorTo.length > 1 ) ? styles.panelErrorsBottom : styles.panelInputs  )}>
                <Box className={styles.selects}>
                    <Box className={styles.selectType}>
                        <Typography sx={{color: '#C2C2C2'}} variant='subtitle2'>Получаю</Typography>

                        {
                            (toCity !== 'Tether (USDT)') &&
                            <Box className={styles.wrapSelect}>                            
                                <FormSelect
                                    size="small"
                                    fullWidth={false}
                                    control={control}
                                    name={'to.option'}
                                    menuItems={MOCK_VARIABLE}
                                />
                            </Box>
                        }

                        </Box>
                    <FormCustomAutocomplete
                        customOnChange={handleCurrency}
                        customSetName='to.currency'
                        getOptionDisabled={(option: string) =>
                            option === getValues('from.city')
                          }
                        control={control}
                        disabled={getValues('to.city') === 'Tether (USDT)'}
                        placeholder={'Выберите город'}
                        renderOption={renderOptionAutocomplete}
                        isOptionEqualToValue={isOptionEqual}
                        name={'to.city'}
                        zIndex='400' 
                        init={toCity || getValues('to.city')} 
                        array={arrayCountryCity} 
                        label="Поиск офиса по городам" />
                </Box>
                <Box className={styles.inputSum}>
                    <FormInputMoney 
                        control={control} 
                        name={'to.sum'}
                        disabled
                        prefix='~ '
                        fullWidth 
                        placeholder={'~9999'} 
                        InputProps={{
                            inputProps: {
                                style: { textAlign: "right" },
                            }
                    }} />
                     <FormCustomAutocomplete
                        customSupportOnChange={handleSupportConvert}
                        disabled={getValues('to.currency') === 'USDT' || toCity === null}
                        control={control}
                        renderOption={renderOptionValueLabelAutocomplete}
                        isOptionEqualToValue={isOptionEqualValue}
                        name={'to.currency'}
                        isChangeValue={true}
                        zIndex='400' 
                        init={currencyTo} 
                        array={arrayToCurrency} 
                        label="Выбор валюты"
                    />
                </Box>
            </Box>
            {
                (Boolean(errors.to) === true || errorTo.length > 1 ) && 
                <Box className={styles.errors}>
                    {
                        handleError(errorTo.length > 1 
                        ? 
                            errorTo 
                        : 
                            errors, currencyTo)
                    }
                </Box>
            }
        </Box>
    )
}
