import { Box, Button, Divider, Link, TextField, Typography } from '@mui/material'
import { CardSubstrate } from 'components'
import React from 'react'
import styles from './successCreatedOrder.module.scss'
import {ReactComponent as Success} from 'assets/icon/successIcon.svg'
import { selectOption } from 'shared'
import { NumericFormat } from 'react-number-format'
import TelegramIcon from '@mui/icons-material/Telegram';

export const SuccessCreatedOrder = (props: any) => {
  const { data, response } = props;

  // const {from, to} = data;

  console.log('data', data)
  return (
    <Box className={styles.containerResponse}>
        <CardSubstrate styled={styles.substrate}>
          <Box className={styles.content}>
            <Success/>
            <Typography mt={'36px'} textAlign='center' variant='body1'>
              Отлично, заявка №{response?.number} создана! 
              Ожидаем вас в чате для продолжения обмена
            </Typography>
            <Typography mt={'24px'} textAlign='center' variant='subtitle2'>
                Занимаемся получением курса для Вас 
                и опубликуем его в специально 
                созданном чат-кассе, ожидаем
                вас по ссылке ниже
            </Typography>

            <Box className={styles.showValues}>
              <Box className={styles.wrapValues}>
                <Box className={styles.city}>
                  <Box className={styles.cityFromTo}>
                    <Typography variant='subtitle2'>Отдаю</Typography>
                    <Typography variant='subtitle2'>&nbsp;{`${selectOption(data?.from?.currency)}`}</Typography>
                  </Box>
                  {
                    data?.from?.currency === 'USDT' 
                    ? 
                      <Typography className={styles.textValues}>
                        {'Tether (USDT)'}
                      </Typography>
                    :
                      <Typography className={styles.textValues}>
                        {`${data?.from?.cityAndCountry?.country}, ${data?.from?.cityAndCountry?.city}`}
                      </Typography>
                  }
                </Box>
                <Box className={styles.valueFromTo}>
                  <NumericFormat
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    value={data?.from?.amount}
                    className={styles.textValues}
                    customInput={TextField}
                    disabled={true}
                    // prefix='~ '
                    thousandSeparator=" "
                    decimalSeparator=","
                    InputProps={{
                        inputProps: {
                            style: { textAlign: "right" },
                        }
                    }} 
                  />
                  <Typography ml={'8px'} className={styles.textValues}>{data?.from?.currency}</Typography>
                </Box>
              </Box>
              <Divider className={styles.divider} />
              <Box className={styles.wrapValues}>
                <Box className={styles.city}>
                  <Box className={styles.cityFromTo}>
                    <Typography variant='subtitle2'>Получаю </Typography>
                    <Typography variant='subtitle2'>&nbsp;{`${selectOption(data?.to?.currency)}`}</Typography>
                  </Box>
                  {
                    data?.to?.currency === 'USDT' 
                    ? 
                      <Typography className={styles.textValues}>
                        {'Tether (USDT)'}
                      </Typography>
                    :
                      <Typography className={styles.textValues}>
                        {`${data?.to?.cityAndCountry?.country}, ${data?.to?.cityAndCountry?.city}`}
                      </Typography>
                  }
                </Box>
                <Box className={styles.valueFromTo}>
                  <NumericFormat
                    value={data?.to?.amount}
                    className={styles.textValues}
                    customInput={TextField}
                    disabled={true}
                    prefix='~ '
                    thousandSeparator=" "
                    decimalSeparator=","
                    InputProps={{
                        inputProps: {
                            style: { textAlign: "right" },
                        }
                    }}
                  />
                  <Typography ml={'8px'} className={styles.textValues}>{data?.to?.currency}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardSubstrate>



        <CardSubstrate styled={styles.substrateWrapButton}>
          <Typography variant='body2'>Примечание</Typography>
          <Typography mt={'8px'} variant='subtitle2'>Указана предварительная сумму к получению. Точная сумма будет доступна в созданной чат-кассе далее.</Typography>
          <Link href={response?.link}>
            <Button 
              className={styles.buttonLinkTg}
            sx={{ "& .MuiButton-startIcon": {
                  marginRight: '32px',
                  marginLeft: '-68px'
                },
              }} 
              color='primary'variant='contained' startIcon={<TelegramIcon className={styles.tgIcon} />}>
              <Box sx={{display: 'flex', flexDirection: 'column'}}>
                ПРОДОЛЖИТЬ
                <Typography variant='subtitle2' style={{
                  color: '#B1D6FF', 
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '17.05px',
                  }}>
                  Перейти в чат
                </Typography> 
                </Box>
            </Button>
          </Link>
        </CardSubstrate>
    </Box>
  )
}
