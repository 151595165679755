// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about_content__-ltWJ {
  padding: 48px;
}
.about_content__-ltWJ .about_subtitle__naiF3 {
  margin-top: 24px;
}
.about_content__-ltWJ .about_description__qFQBk {
  margin-top: 14px;
}`, "",{"version":3,"sources":["webpack://./src/pages/about/about.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,gBAAA;AACR;AAEI;EACI,gBAAA;AAAR","sourcesContent":[".content {\n    padding: 48px;\n\n    .subtitle {\n        margin-top: 24px;\n    }\n\n    .description {\n        margin-top: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `about_content__-ltWJ`,
	"subtitle": `about_subtitle__naiF3`,
	"description": `about_description__qFQBk`
};
export default ___CSS_LOADER_EXPORT___;
