import clsx from 'clsx';
import { MainSubstrate } from './cardSubstrate.interface'
import styles from './cardSubstrate.module.scss'

export const CardSubstrate = (props:MainSubstrate) => {
    const { children, styled } = props;

    return (
      <div className={clsx(styles.mainSubstrate, styled) }>
        {children}
      </div>
    )
}
