import { ReactNode } from 'react';
import '../App.css';

export interface Option {
	value: 'cash' | 'crypto';
	name: string;
}
export const MOCK_VARIABLE = [
    {
        value: 'office',
        label: 'офис'
    },
    {
        value: 'courier',
        label: 'курьер'
    },
]

export const MOCK_CITY = [
    {
        label: 'Россия, Москва',
        value: 'MSK'
    },
    {
        label: 'Россия, Воронеж',
        value: 'VRN'
    },
    {
        label: 'Россия, Санкт-Питербург',
        value: 'SPB'
    },
    {
        label: 'Россия, Владивосток',
        value: 'VVO'
    },
    {
        label: 'Египет, Каир',
        value: 'VVO'
    },
]


export const MOCK_CITY_DATA = [
    {
        label: 'Россия, Москва',
        value: 'MSK'
    },
    {
        label: 'Россия, Санкт-Питербург',
        value: 'SPB'
    },
    {
        label: 'Египет, Каир',
        value: 'VVO'
    },
]


export const options: Option[] = [
	{ value: 'cash', name: 'transfer cash' },
	{ value: 'crypto', name: 'crypto / cash' },
]

// export const CITY = [
//     {
//         value: 'RU MOSCOV',
//         title: 'Россия, Москва'
//     }
// ]

export const MOCK_VALUTE = [
    {
        title: {
            name: 'Российский рубль',
            subName: 'RUB'
        },
        value: 'RUB'
    },
    {
        title: {
            name: 'Китайский юань',
            subName: 'CNY'
        }, 
        value: 'CNY'
    },
    {
        title: {
            name:'Доллар США',
            subName: 'USD'
        }, 
        value: 'USD'
    },
    {
        title: {
            name: 'Евро',
            subName:  'EUR'
        }, 
        value: 'EUR'
    },
]
export const MOCK_VALUTE_FOO = [
    {
        label: 'Российский рубль',
        value: 'RUB'
    },
    {
        label: 'Китайский юань', 
        value: 'CNY'
    },
    {
        label: 'Доллар США',
        value: 'USD'
    },
    {
        label: 'Евро',
        value: 'EUR'
    },
    {
        label: 'Тайский бат',
        value: 'THB'
    },
    {
        label: 'Индонезийский рупий',
        value: 'IDR'
    },
    {
        label: 'Казахстанский тенге',
        value: 'KZT'
    },
    {
        label: 'Турецкая лира',
        value: 'TRY'
    },
    {
        label: 'Грузинский лари',
        value: 'GEL'
    },
    {
        label: 'Армянский драм',
        value: 'AMD'
    },
    {
        label: 'Киргизский сом',
        value: 'KGS'
    },
    {
        label: 'Азербайджанский манат',
        value: 'AZN'
    },
    {
        label: 'Крипто-валюта',
        value: 'USDT'
    },
    {
        label: 'Молдавский лей',
        value: 'MDL'
    },
    {
        label: 'Израильский шекель',
        value: 'ILS'
    },
    {
        label: 'Египетский фунт',
        value: 'EGP'
    },
    {
        label: 'Узбекский сум',
        value: 'UZS'
    },
    {
        label: 'Дирхам ОАЭ',
        value: 'AED'
    },
    {
        label: 'Сербский динар',
        value: 'RSD'
    },
    {
        label: 'Болгарский лев',
        value: 'BGN'
    },
    {
        label: 'Швейцарский франк',
        value: 'CHF'
    },
    {
        label: 'Чешская крона',
        value: 'CZK'
    },
    {
        label: 'Польский злотый',
        value: 'PLN'
    },
    {
        label: 'Британскйи Фунт стерлингов',
        value: 'GBP'
    },
    {
        label: 'Ганский седи',
        value: 'GHS'
    },
    {
        label: 'Конголезский франк',
        value: 'CDF'
    },
    {
        label: 'Западноафриканский франк',
        value: 'XOF'
    },
    {
        label: 'Танзанийский шиллинг',
        value: 'TZS'
    },
    {
        label: 'Кенийский шиллинг',
        value: 'KES'
    },
    {
        label: 'Нигерийская найра',
        value: 'NGN'
    },
    {
        label: 'Гамбийский даласи',
        value: 'GMD'
    },
    {
        label: 'Гвинейский франк',
        value: 'GNF'
    },
    {
        label: 'Ангольская кванза',
        value: 'AOA'
    },
    {
        label: 'Сьерра-леонская Леоне',
        value: 'SLE'
    },
    {
        label: 'Вьетнамский донг',
        value: 'VND'
    },
    {
        label: 'Японская иена',
        value: 'JPY'
    },
    {
        label: 'Гонконгский доллар',
        value: 'HKD'
    },
    {
        label: 'Сингапурский доллар',
        value: 'SGD'
    },
    {
        label: 'Канадский доллар',
        value: 'CAD'
    },
]

export const MOCK_CURRENCY_RU = [
    {
        label: 'Российский рубль',
        value: 'RUB'
    },
    {
        label: 'Доллар США',
        value: 'USD'
    },
]

export const MOCK_CURRENCY_EN = [
    {
        label: 'Российский рубль',
        value: 'RUB'
    },
    {
        label: 'Доллар США',
        value: 'USD'
    },
]

export const MOCK_CITY_COUNTRY = [
    {
        country: "Россия",
        city: "Москва"
    },
    {
        country: "Россия",
        city: "Санкт-Петербург"
    },
    {
        country: "Россия",
        city: "Казань"
    },
    {
        country: "Россия",
        city: "Самара"
    },
    {
        country: "Германия",
        city: "Берлин"
    },
    {
        country: "Германия",
        city: "Дюссельдорф"
    },
    {
        country: "Германия",
        city: "Эссен"
    },
    {
        country: "Германия",
        city: "Дортмунд"
    },
    {
        country: "Германия",
        city: "Кельн"
    },
    {
        country: "Германия",
        city: "Франкфурт"
    },
    {
        country: "Германия",
        city: "Гамбург"
    },
    {
        country: "Германия",
        city: "Ганновер"
    },
    {
        country: "Германия",
        city: "Баден-Баден"
    },
    {
        country: "Германия",
        city: "Лейпциг"
    },
    {
        country: "Германия",
        city: "Мюнхен"
    },
    {
        country: "Германия",
        city: "Дрезден"
    },
    {
        country: "Германия",
        city: "Штутгарт"
    },
    {
        country: "Германия",
        city: "Нюрнберг"
    },
    {
        country: "Германия",
        city: "Бремен"
    },
    {
        country: "Казахстан",
        city: "Алматы"
    },
    {
        country: "Казахстан",
        city: "Астана"
    },
    {
        country: "Кыргызстан",
        city: "Бишкек"
    },
    {
        country: "Узбекистан",
        city: "Ташкент"
    },
    {
        country: "Азербайджан",
        city: "Баку"
    },
    {
        country: "Молдова",
        city: "Кишинев"
    },
    {
        country: "Грузия",
        city: "Тбилиси"
    },
    {
        country: "Грузия",
        city: "Батуми"
    },
    {
        country: "Армения",
        city: "Ереван"
    },
    {
        country: "Гана",
        city: "Аккра"
    },
    {
        country: "Конго",
        city: "Браззавиль"
    },
    {
        country: "Мали",
        city: "Бамако"
    },
    {
        country: "Бенин",
        city: "Порто-Ново"
    },
    {
        country: "Кот-д’Ивуар",
        city: "Ямусукро"
    },
    {
        country: "Танзания",
        city: "Додома"
    },
    {
        country: "Кения",
        city: "Найроби"
    },
    {
        country: "Нигерия",
        city: "Абуджа"
    },
    {
        country: "Гамбия",
        city: "Банжул"
    },
    {
        country: "Сенегал",
        city: "Дакар"
    },
    {
        country: "Гвинея",
        city: "Конакри"
    },
    {
        country: "Ангола",
        city: "Луанда"
    },
    {
        country: "Сьерра-Леоне",
        city: "Фритаун"
    }
]