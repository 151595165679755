import { Box, Button, Checkbox, Link, Typography } from '@mui/material';
import styles from './selectTransfer.module.scss'
import { useCallback, useEffect, useRef, useState } from 'react';
import { CardSubstrate, } from 'components';
import { options } from 'constants/MOCK_CONST';
import { FormToggleButton } from 'components/formToggleButton/FormToggleButton';
import { Controller } from 'react-hook-form';
import { baseApi } from 'api';
import { checkSubmitConvert, variableTransferConvert } from 'shared';
import { WelcomeCards } from './welcomeCards';
import { PanelTransferMoney } from './panelTransferMoney';

export const SelectTransfer = (props:any) => {
  const {handleNext, control, getValues, setValue, errors} = props;
  const [fromCity, setFromCity] = useState(getValues('from.city'));
  const [toCity, setToCity] = useState(getValues('to.city'));
  const [currencyFrom, setCurrencyFrom] = useState(getValues('from.currency'));
  const [currencyTo, setCurrencyTo] = useState(getValues('to.currency'));
  const [errorFrom, setErrorFrom] = useState<string>('');
  const [errorTo, setErrorTo] = useState<string>('');

console.log('currencyFrom', currencyFrom)

  const clearResponseError = () => {
    setErrorFrom('');
    setErrorTo('');
  }

  const handleConvertCurrency = useCallback(async() => {
    clearResponseError()
    const data = getValues();
    

    if(data?.from.currency === data?.to?.currency) {
      const mock_sum = data?.from?.sum - (data?.from?.sum * 0.2 / 100);
      setValue('to.sum', Math.floor(mock_sum));
      return;
    }

    const request = variableTransferConvert(data);

    if(
      checkSubmitConvert(data)
    ) {
        const response = await baseApi.convertCurrencyAndValidate(request);
        if(response.result) {
            setValue('to.sum', Math.floor(response.result))
        } else {
            switch (response?.type) {
              case 'from':
                setErrorFrom(response?.message)
                break;
              case 'in':
                setErrorTo(response?.message)
                break;
              default:
                  setErrorTo(response?.message)
                  break;
              }
        }
    }
},[])

  const prevFromCityRef = useRef(null);
  const prevFromCurrencyRef = useRef(null);
  const prevFromOptionRef = useRef(null);

  const prevToCityRef = useRef(null);
  const prevToCurrencyRef = useRef(null);
  const prevToOptionRef = useRef(null);

  const handleSetVariable = useCallback((newValue: string) => {
    const data = getValues()
    if(newValue === 'crypto') {
      prevFromCityRef.current = getValues('from.city')
      prevFromCurrencyRef.current = getValues('from.currency');
      prevFromOptionRef.current = getValues('from.option');
      prevToCityRef.current = getValues('to.city');
      prevToCurrencyRef.current = getValues('to.currency');
      prevToOptionRef.current = getValues('to.option');

      setValue('from.option', 'crypto');
      setValue('from.city', 'Tether (USDT)');
      setValue('from.currency', 'USDT');
      setFromCity('Tether (USDT)');
      setToCity(prevToCityRef.current || 'Россия, Москва');
    }

    if(newValue === 'cash') {
      setFromCity(prevFromCityRef.current || null);
      setToCity(prevToCityRef.current || null);
      setValue('from.city', prevFromCityRef.current || null);
      setValue('from.currency', prevFromCurrencyRef.current || 'RUB');
      setValue('from.option', prevFromOptionRef.current || 'office');
      setValue('to.city', prevToCityRef.current || null);
      setValue('to.currency',prevToCurrencyRef.current || 'RUB');
      setValue('to.option', prevToOptionRef.current || 'office');
    }

    
    if(checkSubmitConvert(data)) {
      handleConvertCurrency()
    }

    clearResponseError();
  },[]);

  console.log('errors', errors)

  const onChangeData = useCallback(() => {
    setCurrencyFrom(getValues('from.currency'));
    setCurrencyTo(getValues('to.currency'));
    setToCity(getValues('to.city'));
    setFromCity(getValues('from.city'))
  }, []);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <CardSubstrate>
      <Box className={styles.baseCard}>
        <Box>
          <Typography variant="h1">
            ФИНАНСОВАЯ
            <br/>
            <span className={styles.blueWord}>ЛОГИСТИКА</span> 
            <br/>
            ПО ВСЕМУ МИРУ
          </Typography>

          <Typography className={styles.crypto}>
            WITH CRYPTO
          </Typography>

          <Typography className={styles.cryptoDesc} >
            Осуществляйте международные переводы через P2P биржу 
            с криптовалютными связками – наслаждайтесь топовыми 
            курсами без комиссий!
          </Typography>
        </Box>

        <Box>
          <FormToggleButton control={control} name={'variable'} setValue={handleSetVariable} array={options}/>

          <PanelTransferMoney 
            errors={errors} 
            errorFrom={errorFrom}
            errorTo={errorTo}
            handleConvertCurrency={handleConvertCurrency}
            clearResponseError={clearResponseError}
            setValue={setValue} 
            control={control} 
            getValues={getValues}
            fromCity={fromCity}
            toCity={toCity}
            setFromCity={setFromCity}
            setToCity={setToCity}
            onChangeData={onChangeData}
            currencyFrom={currencyFrom}
            currencyTo={currencyTo}
            setCurrencyTo={setCurrencyTo}
            setCurrencyFrom={setCurrencyFrom}
          />

          <Box className={styles.nextContent}>
            <Controller
              name='rules'
              control={control}
              rules={{required: true}}
              render={({ field: props, fieldState: { error } }) => (
                <Box className={styles.checkboxRules}>
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                  <Typography textTransform={'none'}  fontSize={16} variant="subtitle2">
                    {`Согласен ${' '}`}
                    <Link sx={ error && {color: 'red', textDecorationColor: 'red'}} href='/exchange-rules'>c условиями сервиса</Link>
                  </Typography>
                </Box>
              )}
            />

            <Button disabled={errorFrom.length > 1 || errorTo.length > 0} className={styles.buttonNext} onClick={handleNext} variant="contained">Продолжить</Button>
          </Box>
        </Box>
      </Box>
      </CardSubstrate>

      <Box className={styles.welcomeCards}>
        <WelcomeCards />
      </Box>
    </Box>

  )
}
