import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import type { Option } from 'constants/MOCK_CONST';
import { Controller } from 'react-hook-form'

export const FormToggleButton = (props: any) => {
    const { 
        control, 
        name, 
        array,
        className,
        setValue,
    } = props;

    const onSelect = (newValue: any, onChange: any) => {
        if(newValue !== null) {
            onChange(newValue)
            setValue(newValue)
        }
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange, ...fieldProps } }) => (
                    <ToggleButtonGroup
                        fullWidth
                        {...fieldProps}
                        value={value}
                        exclusive
                        onChange={(event, newValue) => onSelect(newValue, onChange)}
                        aria-label="text select variable"
                    >
                        {array.map((option: Option) => (
                            <ToggleButton
                                fullWidth
                                color={'primary'}
                                key={option.value}
                                value={option.value}
                                aria-label={`${option.value}`}
                            >
                                {option.name}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
            )}
        />
    )
}
