// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.currency {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 19.01px;
  font-weight: 700;
  line-height: 23.15px;
  color: #000000;
}

/* html, body {
  width: 100%;
  height: 100%;
} */

#root {
  width: 100vw;
  height: 100vh;
}

:root {
  --main-size-xl: 1440px;
  --main-size-lg: 1024px;
  --main-size-md: 768px;
  --main-size-sm: 425px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;EACpB,cAAc;AAChB;;AAEA;;;GAGG;;AAEH;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,qBAAqB;EACrB,qBAAqB;AACvB","sourcesContent":[".App {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n.currency {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 19.01px;\n  font-weight: 700;\n  line-height: 23.15px;\n  color: #000000;\n}\n\n/* html, body {\n  width: 100%;\n  height: 100%;\n} */\n\n#root {\n  width: 100vw;\n  height: 100vh;\n}\n\n:root {\n  --main-size-xl: 1440px;\n  --main-size-lg: 1024px;\n  --main-size-md: 768px;\n  --main-size-sm: 425px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
