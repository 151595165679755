// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectComponent_selectMui__ZKYbp {
  width: 100%;
  height: 19px;
  padding: 0 2px 0 2px;
  background-color: #006FED;
  border: none;
  text-transform: none;
}`, "",{"version":3,"sources":["webpack://./src/components/select/selectComponent.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,oBAAA;EAKA,yBAAA;EACA,YAAA;EACA,oBAAA;AAHJ","sourcesContent":[".selectMui {\n    width: 100%;\n    height: 19px;\n    padding: 0 2px 0 2px;\n    // font-family: 'Proxima Nova Lt';\n    // font-weight: 400;\n    // font-size: 16px;\n    // line-height: '19px';\n    background-color: #006FED;\n    border: none;\n    text-transform: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectMui": `selectComponent_selectMui__ZKYbp`
};
export default ___CSS_LOADER_EXPORT___;
