// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth_telegramWidget__9IBcZ {
  background-color: rgba(202, 202, 202, 0.6980392157);
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth_telegramWidget__9IBcZ .auth_cardContent__r6D5l {
  width: 478px;
  border-radius: 20px;
  background-color: #FFFFFF;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.auth_telegramWidget__9IBcZ .auth_cardContent__r6D5l .auth_cardTitle__0ghAg {
  margin-top: 40px;
}
.auth_telegramWidget__9IBcZ .auth_cardContent__r6D5l .auth_cardDescription__UCqGr {
  margin-top: 18px;
}
.auth_telegramWidget__9IBcZ .auth_cardContent__r6D5l .auth_buttons__cYm4e {
  margin-top: 36px;
}
.auth_telegramWidget__9IBcZ .auth_cardContent__r6D5l .auth_buttons__cYm4e .auth_buttonBack__PgJCI {
  margin-top: 18px;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/main/step/auth/auth.module.scss"],"names":[],"mappings":"AAAA;EACI,mDAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,kCAAA;UAAA,0BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACI,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AACR;AACQ;EACI,gBAAA;AACZ;AAEQ;EACI,gBAAA;AAAZ;AAIQ;EACI,gBAAA;AAFZ;AAIY;EACI,gBAAA;EACA,YAAA;AAFhB","sourcesContent":[".telegramWidget {\n    background-color: #CACACAB2;\n    position: absolute;\n    width: 100vw;\n    height: 100vh;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    backdrop-filter: blur(5px);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    .cardContent {\n        width: 478px;\n        border-radius: 20px;\n        background-color: #FFFFFF;\n        padding: 40px;\n        display: flex;\n        flex-direction: column;\n        align-items: flex-start;\n\n        .cardTitle {\n            margin-top: 40px;\n        }\n\n        .cardDescription {\n            margin-top: 18px;\n        }\n\n\n        .buttons {\n            margin-top: 36px;\n            \n            .buttonBack {\n                margin-top: 18px;\n                height: 40px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"telegramWidget": `auth_telegramWidget__9IBcZ`,
	"cardContent": `auth_cardContent__r6D5l`,
	"cardTitle": `auth_cardTitle__0ghAg`,
	"cardDescription": `auth_cardDescription__UCqGr`,
	"buttons": `auth_buttons__cYm4e`,
	"buttonBack": `auth_buttonBack__PgJCI`
};
export default ___CSS_LOADER_EXPORT___;
