import { SelectComponent } from "components/select"
import { Controller } from "react-hook-form"

export const FormSelect = (props: any) => {
    const {control, name, className, variant, label, menuItems, size} = props;
    return (
        <Controller
            control={control}
            name={name}
            render={({field: {ref, ...fields} }) => (
                <SelectComponent
                    className={className} 
                    variant={variant} 
                    label={label} 
                    size={size} 
                    menuItems={menuItems} 
                    {...fields}
                />
            )}
        />
    )
}
