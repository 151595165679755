// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createdOrder_content__nFdw6 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.createdOrder_loading__Lyho9 {
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/main/step/createdOrder/createdOrder.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":[".content {\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n}\n\n.loading {\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `createdOrder_content__nFdw6`,
	"loading": `createdOrder_loading__Lyho9`
};
export default ___CSS_LOADER_EXPORT___;
