import { Box, Typography } from "@mui/material";
import { CardSubstrate } from "components";
import styles from './about.module.scss'

export const About = () => {
  return (
    <Box>
        <CardSubstrate >
            <Box className={styles.content}>
                <Typography variant="body1">
                    О сервисе
                </Typography>

                <Typography className={styles.subtitle} variant="subtitle1">
                    Как мы работаем?
                </Typography>

                <Typography className={styles.description} variant="subtitle2">
                    Сервис Kassa.cc дает возможность быстрого, безопасного , 
                    простого обмена электронных валют и вывода денег на банковские карты
                </Typography>

                <Typography className={styles.description} variant="subtitle2">
                    Мы поддерживаем для Вас актуальные и вкусные курсы. Наши дизайнеры разработали 
                    удобный адаптивный интерфейс для обмена как на компьютере, так и на любых 
                    мобильных устройствах. В рамках сервиса действуют партнерская программа и 
                    система скидок, воспользовавшись которыми, Вы сможете совершать обмен валют 
                    на более выгодных условиях. Выгодные курсы каждый день. Быстрый обмен валюты. 
                    Теплое клиентское обслуживание
                </Typography>
                
                <Typography className={styles.description} variant="subtitle2">
                    Обмен доступен круглосуточно
                </Typography>
                <Typography variant="subtitle2">
                    Работа технической поддержки с 8 до 00 по МСК
                </Typography>
                <Typography className={styles.description} variant="subtitle2">
                    Заявки обрабатываются как в автоматическом, так и в ручном режиме, в зависимости 
                    от направления обмена.
                </Typography>
                <Typography variant="subtitle2">
                    Время обработки заявок от 1 до 20 минут.
                </Typography>
                <Typography className={styles.description} variant="subtitle2">
                    У нас Вы можете обменять: Visa/MasterCard RUB, Сберонлайн, Perfect Money USD,
                     e-Voucher USD, Payeer RUB, Payeer USD, AdvCash RUB, AdvCash USD, Exmo RUB, Exmo USD, 
                     Bitcoin, Bitcoin Cash, Ethereum, Ethereum Classic, Litecoin, Dash, Ripple.
                </Typography>
            </Box>
        </CardSubstrate>
    </Box>
  )
}
