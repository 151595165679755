import { Box, Button, Divider, TextField, Tooltip, Typography } from '@mui/material';
import { CardSubstrate } from 'components';
import {ReactComponent as ArrowBack} from 'assets/icon/arrowBack.svg';
import {ReactComponent as Info} from 'assets/icon/info.svg';
import styles from './approve.module.scss';
import { NumericFormat } from 'react-number-format';
import { DeliveryMethods, selectOption } from 'shared';
import { red } from '@mui/material/colors';

export const Approve = (props: any) => {
    const {handleBack, data, handleNext} = props;
    const { from, to } = data;

    const tooltipInfo = 'Точный адрес будет предоставлен далее в созданном чат-кассе с оператором сервиса по данной заявке'


    return (
        <Box className={styles.approveWrapper}>
            <CardSubstrate styled={styles.substrate}>

                <Box className={styles.wrapBack}>
                    <Button onClick={handleBack} className={styles.buttonArrow}>
                        <ArrowBack />
                    </Button>
                </Box>

                <Box className={styles.checkContainer}>
                    <Typography variant='body1'>Проверьте данные</Typography>

                    <Box className={styles.description}>
                        <Typography textAlign={'center'} variant='subtitle2'>
                            Внимательно проверьте данные и мы вышлем 
                            <br/> 
                            лучший курс обмена после получения заявки.
                        </Typography>
                    </Box>

                    <Box className={styles.fromAndTo}>
                        <Box className={styles.number}>
                            1
                        </Box>
                        <Box className={styles.option}>
                            <Typography variant='subtitle2'>
                                Отдаете деньги 
                                
                                 <span className={styles.optionVariable}>
                                    {
                                        from.option === DeliveryMethods.office && ` в ${selectOption(from?.option)}` 
                                    }
                                    {
                                        from.option === DeliveryMethods.courier &&  ` ${selectOption(from?.option)}у`
                                    }
                                    {
                                        from.option === DeliveryMethods.crypto && ` в ${selectOption(from?.option)}` 
                                    }
                                </span>
                            </Typography>
                            <Tooltip arrow placement='bottom-end' 
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            maxWidth: '444px',
                                            padding: '24px'
                                        }
                                    }
                                }}
                                sx={{
                                    backgroundColor: '#006FED', 
                                }} 
                                title={<Box>
                                <Typography color={'#FFFFFF'} variant='body2' >Примечание</Typography>
                                <Typography mt={'8px'} color={'#FFFFFF'} variant='subtitle2'>
                                    {tooltipInfo}
                                    </Typography>
                                    </Box>
                                }>
                                <Info/>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box className={styles.data}>
                        <Box className={styles.city}>
                            <Typography className={styles.actionFromAndTo}>отдаю</Typography>
                            <Typography className={styles.cityCurrencySum} >{from.city}</Typography>
                        </Box>
                        <Box className={styles.currency}>
                            <NumericFormat
                                sx={{
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                  },
                                }}
                                value={from.sum}
                                className={styles.cityCurrencySum}
                                customInput={TextField}
                                disabled={true}
                                thousandSeparator=" "
                                decimalSeparator=","
                                InputProps={{
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }} 
                            />
                            <Typography className={styles.cityCurrencySum}>{from?.currency}</Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ margin: '12px 0', width: '75%', height: '1px'}} />
                    <Box className={styles.fromAndTo}>
                        <Box className={styles.number}>
                            2
                        </Box>
                        <Box className={styles.option}>
                            <Typography fontSize={16} variant='subtitle2'>
                                Получите деньги 
                                <span className={styles.optionVariable}>
                                    {
                                        to.option === DeliveryMethods.office && ` в ${selectOption(to?.option)}е`
                                    }
                                    {
                                        to.option === DeliveryMethods.courier &&  ` ${selectOption(to?.option)}ом`
                                    }
                                    {
                                        to.option === DeliveryMethods.crypto && ` в ${selectOption(to?.option)}` 
                                    }
                                    
                                </span>
                            </Typography>
                            <Tooltip arrow placement='bottom-end'
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            maxWidth: '444px',
                                            padding: '24px'
                                        }
                                    }
                                }}
                                sx={{backgroundColor: '#006FED'}} title={
                                <Box>
                                    <Typography color={'#FFFFFF'} variant='body2' >Примечание</Typography>
                                    <Typography mt={'8px'} color={'#FFFFFF'} variant='subtitle2'>
                                        {tooltipInfo}
                                    </Typography>
                                    </Box>
                                }>
                                <Info/>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box className={styles.data}>
                        <Box className={styles.city}>
                            <Typography className={styles.actionFromAndTo}>отдаю</Typography>
                            <Typography className={styles.cityCurrencySum} >{to.city}</Typography>
                        </Box>
                        <Box className={styles.currency}>
                            <NumericFormat
                                value={to?.sum}
                                className={styles.cityCurrencySum}
                                customInput={TextField}
                                disabled={true}
                                prefix='~ '
                                thousandSeparator=" "
                                decimalSeparator=","
                                InputProps={{
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }} 
                            />
                            <Typography className={styles.cityCurrencySum}>{to?.currency}</Typography>
                        </Box>
                    </Box>
                </Box>
            </CardSubstrate>
            <CardSubstrate styled={styles.substrate}>
                <Box>
                    <Typography variant='body2'>Примечание</Typography>
                    <Typography variant='subtitle2'>Указана предварительная сумму к <br/> получению. Точная сумма будет доступна в <br/> созданной чат-кассе далее.</Typography>

                    <Button onClick={handleNext} className={styles.buttonApprove} variant='contained'>
                        ПОДТВЕРДИТЬ
                    </Button>

                    <Button onClick={handleBack} className={styles.buttonApprove} variant='contained' color='secondary'>
                        НАЗАД
                    </Button>
                </Box>
            </CardSubstrate>
        </Box>
    )
}
