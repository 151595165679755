// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customCurrencyAutocomplete_popup__rjp0s {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 442px;
  background-color: transparent;
  padding: 24px 12px;
  display: flex;
  z-index: 400;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/customCurrencyAutocomplete/customCurrencyAutocomplete.module.scss"],"names":[],"mappings":"AAqBI;EAMI,kBAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;EAEA,6BAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;AA1BR","sourcesContent":["// .wrapPopup {\n//     background-color: #FFFFFF;\n\n//     .showSearch {\n//         height: 23px;\n//         font-size: 19px;\n//         font-weight: 400;\n//         line-height: 19px;\n//         color: #000000;\n//         cursor: pointer;\n//         text-transform: none;\n//     }\n\n//     .hideSearch {\n//         width: 24px;\n//         height: 24px;\n//     }\n//     .showSearch:hover {\n//         background-color: inherit;\n//     }\n\n    .popup {\n        // position: absolute;\n        // top: 0;\n        // left: 0;/\n        // width: 398px;\n        // height: 160px;\n        position: absolute;\n        top: -2px;\n        left: -2px;\n        width: 442px;\n        // height: 160px;\n        background-color: transparent;\n        padding: 24px 12px;\n        display: flex;\n        z-index: 400;\n        border-radius: 8px;\n        // border: 2px solid blue;\n    }\n\n    \n//     .currency {\n//         width: 100%;\n//         display: flex;\n//         align-items: center;\n//         justify-content: space-between;\n//         font-size: 19.01px;\n//         font-weight: 700;\n//         line-height: 23.15px;\n//         color: #000000;\n//         padding: 0 24px;\n//     }\n// }\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `customCurrencyAutocomplete_popup__rjp0s`
};
export default ___CSS_LOADER_EXPORT___;
