import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import styles from './formAutocomplete.module.scss'
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import "index.css";

export const FormAutocomplete = (props: any) => {

  //CURRENCY rework autocomplete optional
  const { 
    init, 
    array, 
    label, 
    zIndex, 
    renderOption, 
    isChangeValue, 
    control, 
    name, 
    disabled, 
    handleBlur, 
    customOnChange, 
    placeholder, 
    className
  } = props;



  const handleSearch = (event: any, newValue: string | any, onChange:any
  ) => {
    if(isChangeValue) {
      onChange(newValue.value)
    } else {
      onChange(newValue)
    }
     
        // onChange(newValue)
        // setValue(newValue)
      // if(!isLabelAll) {
      //     onChange(newValue.label)
      //     setValue(newValue.label)
      // } else {
      //     setValue(newValue.value)
      //     onChange(newValue.value)
      // }
      !!customOnChange && customOnChange()
      !!handleBlur && handleBlur()
    }

  return (
    <Box className={clsx(!!className && className, styles.wrapPopup)}>
 
      
        <Box style={{zIndex: zIndex}} className={styles.popup}>
           <Controller
              control={control}
              name={name}
              render={({ field: { value, onChange, onBlur, ...fieldProps } }) => (
                <Autocomplete
                  {...fieldProps}
                  // onBlur={(event) => {
                  //   // Вызов вашей функции при потере фокуса
                  //   handleBlur();
                  //   // Также вызваем onBlur для react-hook-form
                  //   onBlur(); 
                  // }} 
                  
                  // sx={{ 
                  //     '& + .MuiAutocomplete-popper': {          
                  //     position: 'absolute',
                  //     left: '32px !important',
                  //     width: '100% !important'
                  // }
                  // }}

                  // onBlur={!!handleBlur ? onBlured : onBlur}
                  ListboxProps={{
                    className: 'scrollbarCustom'
                  }}
                  open={true}
                  onOpen={() => true}

                  disableClearable
                  fullWidth
                  value={value}
                  onChange={(e ,value)=> handleSearch(e, value, onChange)}
                  size='small'
                  freeSolo
                  disablePortal
                  options={array}
                  renderOption={renderOption}
                  isOptionEqualToValue={(option:any, value:any) => option.value === value}
                  renderInput={(params) => (
                  <TextField {...params} focused label={label} placeholder={placeholder}  />
                )}
              />
            )}/>
        </Box>
    </Box>
  )
}
