import { Button } from '@mui/material'
import { Controller } from 'react-hook-form'

export const FormButton = (props:any) => {
    const {
        control,
        name,
        title,
        color,
        variant,
        className
    } = props;

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <Button className={className} variant={variant} color={color} {...field}>
                    {title}
              </Button>
            )}
        />
    )
}
