// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descriptionWrapper_content__8lkqZ {
  padding: 48px;
}

.descriptionWrapper_changeCard__k3om2 {
  background-color: #FAFAFA;
}

@media (max-width: 1024px) {
  .descriptionWrapper_content__8lkqZ {
    padding: 0 36px;
  }
  .descriptionWrapper_changeCard__k3om2 {
    background-color: transparent;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/descriptionWrapper/descriptionWrapper.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAGA;EAEI;IACI,eAAA;EADN;EAIE;IACI,6BAAA;EAFN;AACF","sourcesContent":[".content {\n    padding: 48px;\n}\n\n.changeCard {\n    background-color: #FAFAFA;\n}\n\n\n@media (max-width: 1024px){\n\n    .content {\n        padding: 0 36px;\n    }\n\n    .changeCard {\n        background-color: transparent;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `descriptionWrapper_content__8lkqZ`,
	"changeCard": `descriptionWrapper_changeCard__k3om2`
};
export default ___CSS_LOADER_EXPORT___;
