import { Box, Typography } from "@mui/material"
import { CardSubstrate } from "components";
import styles from './exchangeRules.module.scss'

export const ExchangeRules = () => {
  return (
    <Box>
        <CardSubstrate >
            <Box className={styles.content}>
                <Typography variant="body1">
                    ПРАВИЛА ОБМЕНА
                </Typography>

                <Typography className={styles.subtitle} variant="subtitle1">
                    Общие правила
                </Typography>

                <Typography className={styles.description} variant="subtitle2">
                    Мы связываем 2 офиса
                </Typography>

                <Typography  variant="subtitle2">
                {`Офис отправителя и офис получателя. В первом офисе отправитель отдает наличные и получает 
                Tether( или другую криптовалюту), далее отправитель пересылает Tether получателю, 
                или отправитель в первом офисе может сразу согласиться отправить 
                Tether на  кошелек представителя второго офиса.`}
                </Typography>

                {/* <Typography className={styles.subtitle} variant="subtitle1">
                    1. Тезисы
                </Typography>

                <Typography className={styles.description} variant="subtitle2">
                    Мы связываем 2 офиса
                </Typography>
                
                <Typography className={styles.description} variant="subtitle2">
                    Офис отправителя и офис получателя. В первом офисе отправитель отдает наличные и получает Tether( или другую криптовалюту), далее отправитель пересылает Tether получателю, или отправитель в первом офисе может сразу согласиться отправить Tether на  кошелек представителя второго офиса
                </Typography> */}

                
            </Box>
        </CardSubstrate>
    </Box>
  )
}
