// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formCustomAutocomplete_wrapPopup__qHLVH {
  background-color: #FFFFFF;
}
.formCustomAutocomplete_wrapPopup__qHLVH .formCustomAutocomplete_disabled__unXLl {
  cursor: default;
  color: rgba(0, 0, 0, 0.47);
}
.formCustomAutocomplete_wrapPopup__qHLVH .formCustomAutocomplete_unDisabled__AQrzt {
  cursor: pointer;
  color: #000000;
}
.formCustomAutocomplete_wrapPopup__qHLVH .formCustomAutocomplete_showSearch__B3uV\\+ {
  height: 23px;
  font-size: 19px;
  font-weight: 400;
  line-height: 19px;
  text-transform: none;
}
.formCustomAutocomplete_wrapPopup__qHLVH .formCustomAutocomplete_hideSearch__A7Yva {
  width: 24px;
  height: 24px;
}
.formCustomAutocomplete_wrapPopup__qHLVH .formCustomAutocomplete_showSearch__B3uV\\+:hover {
  background-color: inherit;
}
.formCustomAutocomplete_wrapPopup__qHLVH .formCustomAutocomplete_popup__N928O {
  position: absolute;
  top: 0;
  left: 0;
  width: 444px;
  height: 160px;
  background-color: #FFFFFF;
  padding: 24px;
  display: flex;
  z-index: 400;
}
.formCustomAutocomplete_wrapPopup__qHLVH .formCustomAutocomplete_currency__vvIRq {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 19.01px;
  font-weight: 700;
  line-height: 23.15px;
  color: #000000;
  padding: 0 24px;
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/fromCustomAutocomplete/formCustomAutocomplete.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;AACI;EACI,eAAA;EACA,0BAAA;AACR;AACI;EACI,eAAA;EACA,cAAA;AACR;AAEI;EACI,YAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;AAAR;AAGI;EACI,WAAA;EACA,YAAA;AADR;AAGI;EACI,yBAAA;AADR;AAII;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,aAAA;EACA,aAAA;EACA,YAAA;AAFR;AAMI;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;EACA,cAAA;EACA,eAAA;EACA,qBAAA;AAJR","sourcesContent":[".wrapPopup {\n    background-color: #FFFFFF;\n\n    .disabled {\n        cursor: default;\n        color: rgba(0, 0, 0, 0.47);\n    }\n    .unDisabled {\n        cursor: pointer;\n        color: #000000;\n    }\n\n    .showSearch {\n        height: 23px;\n        font-size: 19px;\n        font-weight: 400;\n        line-height: 19px;\n        text-transform: none;\n    }\n\n    .hideSearch {\n        width: 24px;\n        height: 24px;\n    }\n    .showSearch:hover {\n        background-color: inherit;\n    }\n\n    .popup {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 444px;\n        height: 160px;\n        background-color: #FFFFFF;\n        padding: 24px;\n        display: flex;\n        z-index: 400;\n    }\n\n    \n    .currency {\n        width: 100%;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        font-size: 19.01px;\n        font-weight: 700;\n        line-height: 23.15px;\n        color: #000000;\n        padding: 0 24px;\n        background-color: red;\n    }\n}\n\n\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapPopup": `formCustomAutocomplete_wrapPopup__qHLVH`,
	"disabled": `formCustomAutocomplete_disabled__unXLl`,
	"unDisabled": `formCustomAutocomplete_unDisabled__AQrzt`,
	"showSearch": `formCustomAutocomplete_showSearch__B3uV+`,
	"hideSearch": `formCustomAutocomplete_hideSearch__A7Yva`,
	"popup": `formCustomAutocomplete_popup__N928O`,
	"currency": `formCustomAutocomplete_currency__vvIRq`
};
export default ___CSS_LOADER_EXPORT___;
