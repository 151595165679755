// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorBoundaryPage_pageError__IeF0H {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.errorBoundaryPage_pageError__IeF0H .errorBoundaryPage_contentError__OLp5g {
  max-width: 333px;
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.errorBoundaryPage_pageError__IeF0H .errorBoundaryPage_contentError__OLp5g .errorBoundaryPage_buttonReload__ickM0 {
  margin-top: 18px;
}
.errorBoundaryPage_pageError__IeF0H .errorBoundaryPage_contentError__OLp5g .errorBoundaryPage_buttonSupport__bt8y- {
  background-color: #039BE5;
  color: #FFFFFF;
  margin-top: 24px;
}
.errorBoundaryPage_pageError__IeF0H .errorBoundaryPage_contentError__OLp5g .errorBoundaryPage_buttonSupport__bt8y- .errorBoundaryPage_tgIcon__fZaE2 {
  font-size: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/errorBoundaryPage/errorBoundaryPage.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACJ;AACI;EACI,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,sBAAA;AACR;AAKQ;EACI,gBAAA;AAHZ;AAMQ;EACI,yBAAA;EACA,cAAA;EACA,gBAAA;AAJZ;AAMY;EACI,eAAA;AAJhB","sourcesContent":[".pageError {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n\n    .contentError {\n        max-width: 333px;\n        margin-top: 36px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        text-align: center;\n        flex-direction: column;\n\n        // .support {\n        //     background-color: #039BE5;\n        // }\n\n        .buttonReload {\n            margin-top: 18px;\n        }\n\n        .buttonSupport {\n            background-color: #039BE5;\n            color: #FFFFFF;\n            margin-top: 24px;\n\n            .tgIcon {\n                font-size: 40px;\n            }\n        }\n\n       \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageError": `errorBoundaryPage_pageError__IeF0H`,
	"contentError": `errorBoundaryPage_contentError__OLp5g`,
	"buttonReload": `errorBoundaryPage_buttonReload__ickM0`,
	"buttonSupport": `errorBoundaryPage_buttonSupport__bt8y-`,
	"tgIcon": `errorBoundaryPage_tgIcon__fZaE2`
};
export default ___CSS_LOADER_EXPORT___;
