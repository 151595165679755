import { Alert, Box, Snackbar } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Approve, SelectTransfer } from "./step";
import { useForm } from "react-hook-form";
import { options } from "constants/MOCK_CONST";
import { joiResolver } from "@hookform/resolvers/joi";
import { DeliveryMethods, schemaValidationMain, transferSubmit } from "shared";
import { Auth } from "./step/auth/Auth";
import { SnackbarCloseReason } from '@mui/material/Snackbar';
import { CreatedOrder } from "./step/createdOrder";
import styles from './main.module.scss';
import { baseApi } from "api";


export const Main = () => {
  const getSession = sessionStorage.getItem('mainPageStep');

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<any>(null);
  const [dataOrder, setDataOrder] = useState<any>(null)
  const [activeStep, setActiveStep] = useState(getSession ? Number(getSession) : 0);


  useEffect(()=> {
    sessionStorage.removeItem('mainPageStep')
  },[getSession])


  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = (
    event: SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  };

  const {control, formState:{ errors }, trigger, handleSubmit, getValues, setValue} = useForm({
    resolver: joiResolver(schemaValidationMain),
    mode: 'all',
    defaultValues: {
      variable: options[0].value,
      rules: false,
      from: {
        city: 'Россия, Москва',
        option: DeliveryMethods.courier,
        sum: 0,
        currency: 'RUB'
      },
      to: {
        city: 'ОАЭ, Дубай',
        option: DeliveryMethods.office,
        sum: 0,
        currency: 'USD'
      },
      
    }
  })
 
  const handleBack = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  },[]);

  const onSubmit = async (data: any) => {
    
    const transformDataSubmit =  transferSubmit(data);
    const response = await baseApi.postCreateTransfer(transformDataSubmit);

    if(!!response?.link && !!response?.number) {
      setDataOrder(transformDataSubmit)
      setResponse(response);
      setIsLoading(false);
    } else {
      setActiveStep(0);
      setIsOpenError(true)
    }

  } 

  const handleNext = useCallback(() => {
    switch (activeStep) {
      case 0:
        trigger().then((checked) => {
          if(checked) {
            setActiveStep(1)
          } else {
            console.error('isValid', checked)
          }
        });
        return;
      case 1:
        if(!!sessionStorage.getItem('tgId')) {
          console.log('!!sessionStorage', !!sessionStorage.getItem('tgId'))
          handleSubmit(onSubmit)();
          setActiveStep(3);
        } else {
          setActiveStep(2);
        }
      return;
    
      default:
        setActiveStep((prev) => prev + 1);
        return;
    }
  }, [activeStep]);

  return (
    <Box className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {activeStep === 0 && 
          <SelectTransfer
            trigger={trigger}
            errors={errors}
            handleNext={handleNext} 
            control={control} 
            getValues={getValues} 
            setValue={setValue} 
          />
        } 

        {activeStep === 1 && 
          <Approve setValue={setValue} data={getValues()} handleNext={handleNext}  handleBack={handleBack} />
        }

        {
          activeStep === 2 && 
          <Box sx={{display: 'flex' }}>
            <Auth handleBack={handleBack} handleSubmit={handleSubmit} onSubmit={onSubmit} handleNext={handleNext} handleOpenSuccess={handleClick}/>
          </Box>
        }

      </form>
      {
        activeStep === 3 && 
          <CreatedOrder isLoading={isLoading} data={dataOrder} response={response} />
      }

      {/* <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={isOpen} autoHideDuration={4000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Вы успешно авторизоались!
        </Alert>
      </Snackbar> */}
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={isOpenError} autoHideDuration={4000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Произошла ошибка, попробуйте сново!
        </Alert>
      </Snackbar>
    </Box>
  )
}
