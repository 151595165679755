import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { ErrorCreatedOrder } from './errorCreatedOrder';
import { SuccessCreatedOrder } from './successCreatedOrder';
import styles from './createdOrder.module.scss'
import clsx from 'clsx';

export const CreatedOrder = (props: any) => {
    const {error, data, response, isLoading} = props;
    console.log('isLoading', isLoading)

  return (
    <Box className={clsx(styles.content, isLoading && styles.loading) }>
      {isLoading ?
        <CircularProgress size={80}/>
        :
        !!error ? 
          <ErrorCreatedOrder />
          :
          <SuccessCreatedOrder data={data} response={response} />
      }
       
    </Box>
  )
}
