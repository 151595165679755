import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import styles from './formCustomAutocomplete.module.scss'
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import "index.css";

export const FormCustomAutocomplete = (props: any) => {
  const { 
    init, 
    array, 
    label, 
    zIndex, 
    renderOption,
    isOptionEqualToValue,
    getOptionDisabled,
    isChangeValue, 
    control, 
    name, 
    disabled, 
    handleBlur, 
    customOnChange,
    customSupportOnChange,
    customSetName,
    placeholder, 
    className
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(init);

  useEffect(()=> {
    setValue(init)
  },[init])


  const handleOpen = () => {
      if(!disabled) {
        setIsOpen(prev=>!prev)
      }
  }

  const handleSearch = (event: any, newValue: string | any, onChange:any
  ) => {
    if(isChangeValue) {
      onChange(newValue.value)
      setValue(newValue.value)
    } else {
      onChange(newValue)
      setValue(newValue)
    }
     
    
      !!customSupportOnChange && customSupportOnChange()
      !!customOnChange && !!customSetName && customOnChange(name,customSetName)
      !!handleBlur && handleBlur()
      setIsOpen(false)
    }

  return (
    <Box className={clsx(!!className && className, styles.wrapPopup)}>
 
      <Typography
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleOpen()
          }
        }}
        tabIndex={0}
        role="button" 
        aria-label="button"
        aria-pressed="false"
        className={clsx(styles.showSearch, !!disabled ? styles.disabled : styles.unDisabled) }
        onClick={handleOpen}>
          {value || placeholder} 
      </Typography>
      
      {isOpen &&
        <Box style={{zIndex: zIndex}} className={styles.popup}>
           <Controller
              control={control}
              name={name}
              render={({ field: { value, onChange, onBlur, ...fieldProps } }) => (
                <Autocomplete
                  {...fieldProps}
                  sx={{ 
                      '& + .MuiAutocomplete-popper': {          
                      position: 'absolute',
                      left: '32px !important',
                      width: '100% !important'
                  }
                  }}
                  ListboxProps={{
                    className: 'scrollbarCustom'
                  }}
                  open={true}
                  disableClearable
                  onOpen={() => true}
                  getOptionDisabled={getOptionDisabled}
                  fullWidth
                  value={value}
                  onChange={(e ,value)=> handleSearch(e, value, onChange)}
                  size='small'
                  disablePortal
                  options={array}
                  renderOption={renderOption}
                  isOptionEqualToValue={isOptionEqualToValue}
                  renderInput={(params) => (
                  <TextField {...params} focused label={label} placeholder={placeholder}  />
                )}
              />
            )}/>
            <Button className={styles.hideSearch} onClick={handleOpen}>X</Button>
        </Box>
      }
    </Box>
  )
}
